@color: #fafafa;
@color-blue: #34495e;
@color-sky-blue: #8AC0E8;

body {
  .antialiased;
  font-family: 'Open Sans' !important;
  background-color: #f5f5f5;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
}

.login-logo {
  width: 100%;
  padding: 0 40px;
}

.login {
  padding: 1% 2% 1.5% 2%;
  border-radius: 5px;
  margin-top: 10%;
}

.login form input {
  margin-left: 10%;
  width: 80%;
}

.login .error {
  color: #e74c3c;
  margin: 10px 0 -10px 0;
}

form {
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}

.placeholder(...) {
  ::-webkit-input-placeholder { /* WebKit browsers */
    color:    @arguments;
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    @arguments;
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    @arguments;
  }

  :-ms-input-placeholder { /* Internet Explorer 10+ */
   color:    @arguments;
  }
}

.placeholder(darken(@color, 30%));

form input:not([type='radio']):not([type='checkbox']),
form .control,
textarea {
  height: 40px;
  padding: 10px;
  font-family: 'Open Sans';
  border: 0;
  color: #333;
  background-color: #fafafa;
  border: 2px darken(#f5f5f5, 5%) solid;
  //box-shadow: inset 0 1px 2px rgba(0,0,0,0.075);
  outline: none;
}

form .control {
  margin-bottom: 10px;
}

form input[type='checkbox'] {
  margin-top: 30px;
  margin-right: 5px;
}

form input:focus {
  outline: 1px auto #168ef4;
}

form input[type=text]:first-child {
  margin-bottom: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

form select {
  display: block;
}

form .last {
  margin-top: 4px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

form input[type=submit] {
  margin-top: 20px;
  color: lighten(@color, 10%) !important;
  background-color: @color-blue !important;
  border-radius: 3px;
  border: darken(@color-blue, 2%) 1px solid !important;

  &:hover {
    background-color: darken(@color-blue, 5%) !important;
  }

  &:disabled {
    color: darken(#ccc, 40%) !important;
    border: #ccc !important;
    background-color: #ccc !important;
  }
}

.upload form input:not([type='checkbox']) {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 200px;
}

.thumbnail_preview {
  display: none;
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
  border: 2px #ccc solid;
  border-radius: 2px;
  margin-bottom: 5px;
}

.transition (@prop: all, @time: 0.15s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

.navbar {
  margin-bottom: 0;
  background: @color-blue;
  color: #fff;
  border: none !important;
}

@media (max-width: 768px) {
  .navbar {
    position: fixed;
    width: 100%;
    top: 0;
  }

  .content {
    margin-top: 50px;
  }
}

.navbar .navbar-collapse {
  border: none;
}

.navbar .navbar-toggle {
  &:hover, &:focus {
    background-color: darken(@color-blue, 5%) !important;
  }
}

.navbar li {
  background: @color-blue;
}

.navbar li a {
  color: #fff !important;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;

  &:hover {
    background-color: darken(@color-blue, 5%) !important;
  }
}

.navbar .user {
  display: inline-block;
  float: left;
  margin-top: 16px;
  margin-left: 20px;
}

.navbar .logo {
  float: left;
  width: 110px;
  height: auto;
  padding: 7px 0 5px 0;

  // TODO Hover on here
}

@media (max-width: 768px) {
  .navbar .logo {
    padding-top: 10px;
    padding-left: 15px;
  }
}

.navbar .icon-bar {
  background: #fff !important;
}

.upload form p {
  margin-top: 5px;
}

.upload form span {
  font-weight: 700;
}

.upload form label span {
  font-weight: 400;
}

.upload form span.error {
  display: none;
}

.upload form span.error.thumbnail {
  border: none;
  background: none;
}

.upload form span.error[style*="display: inline"] {
  display: block !important;
  margin-top: -5px;
  margin-bottom: 10px;
  font-weight: 400 !important;
  color: #e74c3c;
  //border: #e74c3c solid 1px;
  clear: both;
}

// TODO Find out why this overflows
@media (max-width: 768px) {
  .input-group {
    overflow: hidden;
  }
}

.btn-group > .btn-selector {
  border-color: @color-blue;
  background-color: lighten(@color-blue, 20%);
  color: #fff;
}

.btn-group > .btn-selector:hover {
  background-color: @color-blue;
}

.btn-group > .btn-selector.active {
  background-color: @color-blue;
  color: #fff;
}

.btn.btn-checkbox {
  margin-bottom: 5px;
  margin-right: 5px;
  box-shadow: inset 0 0 0 1px lighten(@color-blue, 20%);
  background-color: lighten(@color-blue, 20%);
  color: #fff;
}

.btn.btn-checkbox:hover {
  box-shadow: inset 0 0 0 1px @color-blue;
  background-color: @color-blue;
}

.btn.btn-checkbox.active {
  box-shadow: none;
  background-color: @color-blue;
  color: #fff;
}

.user-container {
  position: relative;
  margin-bottom: 10px;
}

.user-box {
  height: auto;
  position: relative;
  background: #fff;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;

  .box-content {
    padding: 20px;

    h3 {
      margin: 0;
      line-height: 1.3;
      font-size: 18px;
    }

    .role {
      width: 100%;
      display: block;
      font-weight: 700;
      margin-bottom: 10px;
    }

    span, a {
      margin-left: 2px;
    }
  }
}

footer {
  width: 100%;
  height: 100px;
}

.new-version {
  color: lighten(@color, 10%) !important;
  background-color: @color-blue !important;
  border-radius: 3px;
  border: darken(@color-blue, 2%) 1px solid !important;

  &:hover {
    background-color: darken(@color-blue, 5%) !important;
  }
}

.stats-button {
  color: lighten(@color, 10%) !important;
  background-color: @color-sky-blue !important;
  border-radius: 3px;
  border: darken(@color-sky-blue, 2%) 1px solid !important;
  margin-right: 10px;

  &:hover {
    background-color: darken(@color-sky-blue, 5%) !important;
  }
}

.map-container {
  margin-bottom: 30px;
}

.btn-atlas {
  color: lighten(@color, 10%) !important;
  background-color: @color-blue !important;
  border-radius: 3px;
  border: darken(@color-blue, 2%) 1px solid !important;

  &:hover {
    background-color: darken(@color-blue, 5%) !important;
  }
}

.map-block {
  background: #fff;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  padding: 20px 30px 10px 30px;

  h3 {
    margin: 0 0 10px 0;
  }

  .top-button-container {
    float: right;
    margin-top: -30px;
  }

  .type {
    float: left;
    color: #fff;
    height: 30px;
    font-weight: 700;
    padding: 5px 10px;
    width: 130px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 3px;
    margin-bottom: 20px;

    &.minigame {
      background-color: rgba(46, 204, 113, 0.8);
    }

    &.adventuremap {
      background-color: rgba(52, 152, 219, 0.8);
    }

    &.worldtemplate {
      background-color: rgba(155, 89, 182, 0.8);
    }

    &.experience {
      background-color: rgba(241, 196, 15, 1.0);
    }

    &.inspiration {
      background-color: rgba(231, 76, 60, 1.0);
    }
  }

  .versions {
    clear: both;
  }

  .stats-container {
    clear: both;
  }

  .stats-block {
    background-color: darken(@color, 2%);
    -webkit-transition: box-shadow 0.2s;
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    color: #333;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  }

  .version {
    width: 100%;
    margin-bottom: 20px;
    background-color: darken(@color, 2%);
    color: #333;
    border-radius: 5px;

    overflow: hidden;

    .image-buttons {
      clear: both;
      width: 160px;
    }

    .btn {
      border-radius: 0px !important;
      border-top: none !important;
    }

    img {
      float: left;
      height: 160px;
      width: 160px;
    }

    .resourcepack {
      float: left;
      height: 30px;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      position: absolute;
      left: 15px;
      padding: 5px;
      border-top-left-radius: 5px;
      width: 160px;
    }

    .box-content {
      float: left;
      padding: 20px;

      .version-span {
        margin-top: -10px;
        width: 100%;
        display: block;
        font-weight: 700;
      }

      span, a {
        width: 100%;
        margin-left: 2px;
      }

      .status {
        color: #A7A7A7;

        &.warning {
          color: #d04c4c;
        }

        &.success {
          color: #00a651;
        }
      }

      .download, .push {
        font-size: 30px;
        width: 30px;
        height: 30px;
      }

      .download {
        position: absolute;
        bottom: 30px;
        left: 270px;
      }

      .push {
        position: absolute;
        bottom: 30px;
        left: 310px;
      }
    }
  }
}
