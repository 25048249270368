body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans' !important;
  background-color: #f5f5f5;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
}
.login-logo {
  width: 100%;
  padding: 0 40px;
}
.login {
  padding: 1% 2% 1.5% 2%;
  border-radius: 5px;
  margin-top: 10%;
}
.login form input {
  margin-left: 10%;
  width: 80%;
}
.login .error {
  color: #e74c3c;
  margin: 10px 0 -10px 0;
}
form {
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aeaeae;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aeaeae;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #aeaeae;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #aeaeae;
}
form input:not([type='radio']):not([type='checkbox']),
form .control,
textarea {
  height: 40px;
  padding: 10px;
  font-family: 'Open Sans';
  border: 0;
  color: #333;
  background-color: #fafafa;
  border: 2px #e8e8e8 solid;
  outline: none;
}
form .control {
  margin-bottom: 10px;
}
form input[type='checkbox'] {
  margin-top: 30px;
  margin-right: 5px;
}
form input:focus {
  outline: 1px auto #168ef4;
}
form input[type=text]:first-child {
  margin-bottom: 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
form select {
  display: block;
}
form .last {
  margin-top: 4px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
form input[type=submit] {
  margin-top: 20px;
  color: #ffffff !important;
  background-color: #34495e !important;
  border-radius: 3px;
  border: #304457 1px solid !important;
}
form input[type=submit]:hover {
  background-color: #2b3c4e !important;
}
form input[type=submit]:disabled {
  color: #666666 !important;
  border: #ccc !important;
  background-color: #ccc !important;
}
.upload form input:not([type='checkbox']) {
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
}
textarea {
  width: 100%;
  height: 200px;
}
.thumbnail_preview {
  display: none;
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
  border: 2px #ccc solid;
  border-radius: 2px;
  margin-bottom: 5px;
}
.navbar {
  margin-bottom: 0;
  background: #34495e;
  color: #fff;
  border: none !important;
}
@media (max-width: 768px) {
  .navbar {
    position: fixed;
    width: 100%;
    top: 0;
  }
  .content {
    margin-top: 50px;
  }
}
.navbar .navbar-collapse {
  border: none;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: #2b3c4e !important;
}
.navbar li {
  background: #34495e;
}
.navbar li a {
  color: #fff !important;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}
.navbar li a:hover {
  background-color: #2b3c4e !important;
}
.navbar .user {
  display: inline-block;
  float: left;
  margin-top: 16px;
  margin-left: 20px;
}
.navbar .logo {
  float: left;
  width: 110px;
  height: auto;
  padding: 7px 0 5px 0;
}
@media (max-width: 768px) {
  .navbar .logo {
    padding-top: 10px;
    padding-left: 15px;
  }
}
.navbar .icon-bar {
  background: #fff !important;
}
.upload form p {
  margin-top: 5px;
}
.upload form span {
  font-weight: 700;
}
.upload form label span {
  font-weight: 400;
}
.upload form span.error {
  display: none;
}
.upload form span.error.thumbnail {
  border: none;
  background: none;
}
.upload form span.error[style*="display: inline"] {
  display: block !important;
  margin-top: -5px;
  margin-bottom: 10px;
  font-weight: 400 !important;
  color: #e74c3c;
  clear: both;
}
@media (max-width: 768px) {
  .input-group {
    overflow: hidden;
  }
}
.btn-group > .btn-selector {
  border-color: #34495e;
  background-color: #587ca0;
  color: #fff;
}
.btn-group > .btn-selector:hover {
  background-color: #34495e;
}
.btn-group > .btn-selector.active {
  background-color: #34495e;
  color: #fff;
}
.btn.btn-checkbox {
  margin-bottom: 5px;
  margin-right: 5px;
  box-shadow: inset 0 0 0 1px #587ca0;
  background-color: #587ca0;
  color: #fff;
}
.btn.btn-checkbox:hover {
  box-shadow: inset 0 0 0 1px #34495e;
  background-color: #34495e;
}
.btn.btn-checkbox.active {
  box-shadow: none;
  background-color: #34495e;
  color: #fff;
}
.user-container {
  position: relative;
  margin-bottom: 10px;
}
.user-box {
  height: auto;
  position: relative;
  background: #fff;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
.user-box .box-content {
  padding: 20px;
}
.user-box .box-content h3 {
  margin: 0;
  line-height: 1.3;
  font-size: 18px;
}
.user-box .box-content .role {
  width: 100%;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}
.user-box .box-content span,
.user-box .box-content a {
  margin-left: 2px;
}
footer {
  width: 100%;
  height: 100px;
}
.new-version {
  color: #ffffff !important;
  background-color: #34495e !important;
  border-radius: 3px;
  border: #304457 1px solid !important;
}
.new-version:hover {
  background-color: #2b3c4e !important;
}
.stats-button {
  color: #ffffff !important;
  background-color: #8AC0E8 !important;
  border-radius: 3px;
  border: #81bbe6 1px solid !important;
  margin-right: 10px;
}
.stats-button:hover {
  background-color: #75b5e4 !important;
}
.map-container {
  margin-bottom: 30px;
}
.btn-atlas {
  color: #ffffff !important;
  background-color: #34495e !important;
  border-radius: 3px;
  border: #304457 1px solid !important;
}
.btn-atlas:hover {
  background-color: #2b3c4e !important;
}
.map-block {
  background: #fff;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  padding: 20px 30px 10px 30px;
}
.map-block h3 {
  margin: 0 0 10px 0;
}
.map-block .top-button-container {
  float: right;
  margin-top: -30px;
}
.map-block .type {
  float: left;
  color: #fff;
  height: 30px;
  font-weight: 700;
  padding: 5px 10px;
  width: 130px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 3px;
  margin-bottom: 20px;
}
.map-block .type.minigame {
  background-color: rgba(46, 204, 113, 0.8);
}
.map-block .type.adventuremap {
  background-color: rgba(52, 152, 219, 0.8);
}
.map-block .type.worldtemplate {
  background-color: rgba(155, 89, 182, 0.8);
}
.map-block .type.experience {
  background-color: #f1c40f;
}
.map-block .type.inspiration {
  background-color: #e74c3c;
}
.map-block .versions {
  clear: both;
}
.map-block .stats-container {
  clear: both;
}
.map-block .stats-block {
  background-color: #f5f5f5;
  -webkit-transition: box-shadow 0.2s;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.map-block .version {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
  overflow: hidden;
}
.map-block .version .image-buttons {
  clear: both;
  width: 160px;
}
.map-block .version .btn {
  border-radius: 0px !important;
  border-top: none !important;
}
.map-block .version img {
  float: left;
  height: 160px;
  width: 160px;
}
.map-block .version .resourcepack {
  float: left;
  height: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  left: 15px;
  padding: 5px;
  border-top-left-radius: 5px;
  width: 160px;
}
.map-block .version .box-content {
  float: left;
  padding: 20px;
}
.map-block .version .box-content .version-span {
  margin-top: -10px;
  width: 100%;
  display: block;
  font-weight: 700;
}
.map-block .version .box-content span,
.map-block .version .box-content a {
  width: 100%;
  margin-left: 2px;
}
.map-block .version .box-content .status {
  color: #A7A7A7;
}
.map-block .version .box-content .status.warning {
  color: #d04c4c;
}
.map-block .version .box-content .status.success {
  color: #00a651;
}
.map-block .version .box-content .download,
.map-block .version .box-content .push {
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.map-block .version .box-content .download {
  position: absolute;
  bottom: 30px;
  left: 270px;
}
.map-block .version .box-content .push {
  position: absolute;
  bottom: 30px;
  left: 310px;
}
